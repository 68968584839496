import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { resolve } from "q";
import { Globals } from "../../../services/Model/global";
import { Match } from "../../../services/Model/match";
import { SportServiceService } from "../../../services/sport-service.service";
const JSMpeg = require("jsmpeg-player");

declare var $: any;
import { ToastrManager } from "ng6-toastr-notifications";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/services/shared.service";

@Component({
  selector: "app-rightbar",
  templateUrl: "./rightbar.component.html",
  styleUrls: ["./rightbar.component.scss"],
})
export class RightbarComponent implements OnInit, AfterViewInit {
  private stakemsg: any;
  public showBalanceData = "";
  public isconfirm: any;
  public loading1: any;
  public MatchStack: any[];
  public betslipinfo;
  public MatchId: any;
  public MarketId: any;
  public loading: any;
  public SportId: any;
  public betslipType: any;
  public loginUserData: string | any;
  public userId: any;
  public usertypeId: any;
  public betAllDetail: any;
  public IsShowBetInform: any;
  public subscriptionBets: any;
  public back: any;
  public IsShowBetInfo;
  public IsToggle: any;
  private betUserData: any;
  private stakeIds: any = [];
  private total_liability: any;
  public matchedBets: any;
  private unmatchedBets: any;
  public matchedBetslength: any;
  public unmatchedBetslength: any;
  private favdata: any;
  public unfavdata: any;
  public oneClickSelectedBet: any;
  private btnActive: any;
  private config_max_odd_limit: any;
  private isActive: boolean;
  private stackval: any;
  public ischeckconfirm: any;
  private setRef: number;
  isSticky: boolean = false;
  public latestLength: any;
  public player: any;
  public selectedmatch: any;
  balanceData: any;
  OneClickStackButton: any;
  oddBetSlipValArray: any = [];
  MatchStackButton: any = [];
  fancyBetslength: any;
  fancyBets: any;
  isShowTabs;
  betsShow;
  betsSlipShow;
  buttondata;
  public tvCodes: any;
  public tvUrlData: any;
  public hostname: any;
  public tvUrlCricket: any;
  public showLiveTv;
  public unsubs: Subscription;
  public live_sport_tv_url;

  @HostListener("window:scroll", ["$event"])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 20;
  }
  @ViewChild("myCanvas", { static: false }) myCanvas: ElementRef;

  public context: HTMLCanvasElement;

  constructor(
    public matchModel: Match,
    public route: ActivatedRoute,
    public matchService: SportServiceService,
    public globals: Globals,
    public router: Router,
    public toastr: ToastrManager,
    public sanitizer: DomSanitizer,
    private shared: SharedService
  ) {
    this.IsToggle =
      this.globals.IsToggle == undefined ? false : this.globals.IsToggle;
    this.btnActive =
      this.globals.btnActive == undefined ? false : this.globals.btnActive;

    this.router.events.subscribe((event) => {
      if (this.subscriptionBets) {
        this.subscriptionBets.unsubscribe();
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.MatchId = params["MatchId"];
      this.MarketId = params["MarketId"];
      this.SportId = params["SportId"];
    });
    // Subscriber for close sidebar when it is open on match-odds component.
    this.unsubs = this.shared.getShowSideBar().subscribe((res) => {
      this.showLiveTv = res;
    });
  }

  ngOnInit() {
    this.player = "";
    this.getMatchname();
    this.getTvUrl();
    this.hostname = window.location.hostname;
    this.tvUrlCricket = null;
    this.betslipType = "matched";
    this.isShowTabs = true;
    this.betsShow = false;
    this.betsSlipShow = false;
    if (localStorage.length > 1) {
      if (this.router.url.split("?")[0] == "/main/exchange-details") {
        this.fetchBetList();
      } else {
        this.getAllBets();
      }
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.split("?")[0] == "/main/exchange-details") {
            this.fetchBetList();
          } else {
            this.getAllBets();
          }
        }
      });

      this.total_liability = 0;
      this.loginUserData = JSON.parse(localStorage.getItem("UserLoginData"));
      let stackValues = this.loginUserData.stack.split(",");
      let oneclickStack = this.loginUserData.one_click_stack.split(",");
      //debugger;
      this.MatchStackButton = stackValues;

      if (localStorage.getItem("MatchStackButton")) {
        //debugger;
        this.globals.MatchStackButton = localStorage
          .getItem("MatchStackButton")
          .split(",");
      } else {
        this.globals.MatchStackButton = this.MatchStackButton;
      }
      this.OneClickStackButton = oneclickStack;
      this.setRef = 0;
      this.ischeckconfirm =
        this.ischeckconfirm == undefined
          ? "N"
          : localStorage.getItem("is_confirm_bet");
      this.showBalanceData = "showBalanceData";
      this.betslipinfo = true;
      this.IsShowBetInform =
        localStorage.getItem("IsShowBetInform1") != undefined
          ? localStorage.getItem("IsShowBetInform1")
          : true;
      this.userId = this.loginUserData.user_id;
      this.usertypeId = this.loginUserData.type;
      //  this.getChipDatabyId();
      this.MatchStack = this.matchModel.stackSetting;
      this.selectedmatch = JSON.parse(localStorage.getItem("selectedMatch"));
    }
    this.getUserMatchStack();
  }
  goToReports(path) {
    this.router.navigate(["s/" + path]);
  }
  ngAfterViewInit(): void {
    // this.context = (<HTMLCanvasElement>this.myCanvas.nativeElement);
  }
  getTVURL() {
    this.live_sport_tv_url = localStorage.getItem("live_sport_tv_url");
    if (this.live_sport_tv_url == null || this.live_sport_tv_url == "null")
      this.live_sport_tv_url = false;
    if (this.live_sport_tv_url) {
      this.live_sport_tv_url = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.live_sport_tv_url
      );
    }
  }
  getUserMatchStack() {
    //debugger
    this.matchService.getUserMatchStack().subscribe(
      (data) => {
        this.buttondata = data.data;
        let stackValues = this.buttondata.split(",");
        this.MatchStackButton = stackValues;
        this.globals.MatchStackButton = stackValues;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getMatchname() {
    this.matchService.getMatchname().subscribe(
      (response) => {
        this.tvCodes = response;
      },
      (error) => {}
    );
  }
  getTvUrl() {
    this.matchService.getTvUrl().subscribe(
      (response) => {
        this.tvUrlData = response.data;

        console.log("this.tvUrlData", this.tvUrlData);
      },
      (error) => {}
    );
  }

  ChangeFrame(id) {
    let tvUrlData = this.tvUrlData;
    if (id == 1) {
      this.tvUrlCricket = tvUrlData.tv1;
    } else if (id == 2) {
      this.tvUrlCricket = tvUrlData.tv2;
    } else if (id == 3) {
      this.tvUrlCricket = tvUrlData.tv3;
    } else if (id == 4) {
      this.tvUrlCricket = tvUrlData.tv4;
    } else {
      this.showLiveTv = "";
    }
    this.tvUrlCricket = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.tvUrlCricket
    );
  }
  setbtn(indx) {
    this.setRef = indx;
  }

  update_confirmation_setting(isConfirm) {
    let finalco = isConfirm == true ? "N" : "Y";
    if (isConfirm == undefined) {
      localStorage.setItem("is_confirm_bet", "Y");
    } else {
      localStorage.setItem("is_confirm_bet", finalco);
    }
  }

  deleteUser(betId, userId) {
    let result = confirm("Are you sure want to delete Records Unmatched");
    if (result) {
      this.matchService.deleteUserbet(betId, userId).subscribe(
        (data) => {
          this.stakemsg = data.message;
          //    this.toastr.successToastr(this.stakemsg);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  clearAll = function () {
    this.betslipinfo = true;
    this.IsShowBetInfo = false;
    this.total_liability = 0;
    this.isActive = false;
    for (let i = 0; i < this.matchModel.stakeIds.length; i++) {
      this.matchModel.stake2["field_" + this.matchModel.stakeIds[i].UId] = 0;
    }
  };

  getChipDatabyId() {
    this.matchService.getChipDatabyId().subscribe(
      (data) => {
        if (!data.error) {
          if (data.data != undefined) {
            // this.matchModel.marqueText = data.data.site_message;
            this.balanceData = data.data;
            //    this.Balance = data.data.balance;
            //  this.FreeChips = data.data.freechips;
            //   this.Liability = data.data.liability;
            //   this.P_L = data.data.profit_loss;
            if (
              data.data.lock_user == "1" ||
              data.data.close_account == "1" ||
              data.data.is_online == "0"
            ) {
              this.logout();
            }
          }
        } else if (data.code == 412) {
          localStorage.clear();
          this.router.navigate(["login"]);
          setTimeout(() => resolve(window.location.reload()), 200);
        }
      },
      (err) => {
        //debugger;
        if (err.code == 412) {
          this.router.navigate(["login"]);
        }
        console.log(err);
        if (this.router.url.split("?")[0] != "/login") {
          setTimeout(() => resolve(this.getChipDatabyId()), 4000);
        }
      },
      () => {
        if (this.router.url.split("?")[0] != "/login") {
          setTimeout(() => resolve(this.getChipDatabyId()), 4000);
        }
      }
    );
  }

  logout() {
    this.matchService.logout().subscribe(
      (result) => {
        localStorage.UserLoginData = null;
        this.router.navigate(["login"]);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setfavourite(marketid) {
    this.favdata = {
      market_id: marketid,
    };
    this.matchService.setFavorite(this.favdata).subscribe(
      (data) => {
        this.stakemsg = data.message;
        // this.toastr.successToastr(this.stakemsg);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setUnfavourite(marketid) {
    this.unfavdata = {
      market_id: marketid,
    };
    this.matchService.setUnFavorite(this.unfavdata).subscribe(
      (data) => {
        this.stakemsg = data.message;
        //this.toastr.successToastr(this.stakemsg);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getBetSlipDataForOdds() {
    //debugger;
    this.matchModel.tempArray = [];
    let oddBetSlipVal = JSON.parse(localStorage.getItem("betList"));
    this.oddBetSlipValArray.push(oddBetSlipVal);
    this.matchModel.tempArray.push(oddBetSlipVal);
    if (this.globals.one_click_enable == "true") {
      this.matchModel.tempArray[0].stake = this.globals.oneClickSelectedBet;
      this.matchModel.tempArray[0].p_l =
        this.matchModel.tempArray[0].odds * this.matchModel.tempArray[0].stake -
        this.matchModel.tempArray[0].stake;
      this.matchModel.loading1 = true;

      this.Place_Order(this.matchModel.tempArray);
    }
    this.matchModel.betsSlipShow = true;
  }

  Place_Order(betValue) {
    this.betAllDetail = JSON.parse(localStorage.getItem("betAllDetail"));
    // console.log(JSON.parse(localStorage.getItem("betAllDetail")), "betdetail");
    this.isconfirm = true;
    const payload = {
      Match_id: this.betAllDetail.match[0].match_id,
      Market_id: this.betAllDetail.match[0].market_id,
      Sport_id: this.betAllDetail.match[0].sport_id,
      Sport_name: this.betAllDetail.match[0].sport_name,
      Is_fancy: betValue[0].is_fancy == 0 ? false : true,
      Bhav_detail:  this.betAllDetail.match
        .reduce((a, b) => {
          a = [...a, ...b.runner_json];
          return a;
        }, [])
        .map((i, index) => {
          return {
            back: i.back.map((j, index) => {
              return {
                selection_id: i.selectionId,
                current_bhav: j.price,
              };
            }),
            lay: i.lay.map((j, index) => {
              return {
                selection_id: i.selectionId,
                current_bhav: j.price,
              };
            }),
          };
        }),
    };
    this.matchService.syncupRecord(payload).subscribe((data) => {
       console.log(data)
    }, (err) => {
        this.loading = false;

        console.log(err);
    });
    if (localStorage.getItem("is_confirm_bet") == "Y") {
      this.isconfirm = confirm("Are you sure you want to place your bet?");
    } else {
      // this.isconfirm = false;
    }

    if (this.isconfirm) {
      this.loading = true;
      if (betValue[0].is_fancy) {
          let betData = {
              'fancy_id': betValue[0].fancy_id,
              'run': betValue[0].odds,
              'stack': parseInt(betValue[0].stake),
              'is_back': betValue[0].is_back + '',
              'size': betValue[0].size
          };
          this.matchService.saveFancyBet(betData).subscribe((data) => {
              this.matchModel.tempArray = [];
              this.stakemsg = data.message;
              this.loading = false;
              this.matchModel.loading1 = false;

              if (data.error == true) {
                  this.toastr.errorToastr(this.stakemsg, null, {toastTimeout: 2000});

              } else {
                  this.toastr.successToastr(this.stakemsg, null, {toastTimeout: 1000});
              }
          }, (err) => {
              this.loading = false;
              this.stakemsg = err.message;

              this.toastr.errorToastr(this.stakemsg, null, {toastTimeout: 2000});

              console.log(err);
          });
      } else {
          let betData = {
              'market_id': betValue[0].market_id,
              'selection_id': betValue[0].selection_id,
              'odds': betValue[0].odds,
              'stack': parseInt(betValue[0].stake),
              'is_back': betValue[0].is_back + '',
          };
          this.matchService.saveBet(betData).subscribe((data) => {
              this.matchModel.tempArray = [];
              this.stakemsg = data.message;
              this.loading = false;
              this.matchModel.loading1 = false;

              if (data.error == true) {
                  this.toastr.errorToastr(this.stakemsg);

              } else {
                  this.toastr.successToastr(this.stakemsg, '', {toastTimeout: 1000});
              }
          }, (err) => {
              this.loading = false;
              this.stakemsg = err.message;

              this.toastr.errorToastr(this.stakemsg, null, {toastTimeout: 2000});

          });
      }
    }
  }

  calculateP_lOnStack(stake, stkbtn, isback, back) {
    this.stakeIds = this.matchModel.stakeIds;

    this.stackval = back.stake == "" || back.stake == null ? 0 : back.stake;
    back.stake = parseFloat(stkbtn);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = back.odds * back.stake - back.stake;
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (
        back.priceVal <= this.config_max_odd_limit ||
        back.is_session_fancy == "Y" ||
        true
      ) {
        if (back.priceVal > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = pval * back.stake - back.stake;
          } else {
            back.p_l = back.priceVal * back.stake - back.stake;
          }
          this.matchModel.ProfitLoss = back.p_l;
        }
      }
    } else {
      let msg = "";
      if (back.is_session_fancy == "Y") {
        msg =
          "Max session bet liability is " + parseFloat(back.max_bet_liability);
      } else {
        msg =
          "Max market bet liability is " + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }

  calculateP_lOnStackOnInput(stake, stkbtn, isback, back) {
    this.stakeIds = this.matchModel.stakeIds;

    // this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = back.odds * back.stake - back.stake;
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (
        back.priceVal <= this.config_max_odd_limit ||
        back.is_session_fancy == "Y" ||
        true
      ) {
        if (back.priceVal > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = pval * back.stake - back.stake;
          } else {
            back.p_l = back.priceVal * back.stake - back.stake;
          }
          this.matchModel.ProfitLoss = back.p_l;
        }
      }
    } else {
      let msg = "";
      if (back.is_session_fancy == "Y") {
        msg =
          "Max session bet liability is " + parseFloat(back.max_bet_liability);
      } else {
        msg =
          "Max market bet liability is " + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }

  fetchBetList() {
    this.selectedmatch = JSON.parse(localStorage.getItem("selectedMatch"));
    var betsVal = {
      match_id: this.selectedmatch.match_id,
    };
    this.subscriptionBets = this.matchService
      .getBetsByMatchId(betsVal)
      .subscribe(
        (data) => {
          if (data.data != null) {
            this.latestLength = data.data.length;
            this.matchModel.latestLength = this.latestLength;
            this.betUserData = data.data;
            this.matchedBets = this.betUserData.filter(
              (t) => t.is_fancy == "1" || t.is_matched == "1"
            );
            this.unmatchedBets = this.betUserData.filter(
              (t) => t.is_matched == "0"
            );
            this.fancyBets = this.betUserData.filter(
              (t) => t.is_fancy == "1" && t.is_matched == "1"
            );
            this.fancyBetslength = this.fancyBets.length;
            this.matchedBetslength = this.matchedBets.length;
            this.unmatchedBetslength = this.unmatchedBets.length;
          } else {
            this.matchedBetslength = 0;
            this.unmatchedBetslength = 0;
          }
        },
        (err) => {
          console.log(err);
        },
        () => {
          if (this.router.url.split("?")[0] == "/main/exchange-details") {
            setTimeout(() => resolve(this.fetchBetList()), 2000);
          }
        }
      );
  }

  getAllBets() {
    this.subscriptionBets = this.matchService.getAllBets().subscribe(
      (data) => {
        this.betUserData = data.data;
        this.matchedBets = this.betUserData;
        if (this.matchedBets != null) {
          this.matchedBets = this.betUserData.filter(
            (t) => t.is_fancy == "0" && t.is_matched == "1"
          );
          this.unmatchedBets = this.betUserData.filter(
            (t) => t.is_matched == "0"
          );
          this.fancyBets = this.betUserData.filter(
            (t) => t.is_fancy == "1" && t.is_matched == "1"
          );
          this.fancyBetslength = this.fancyBets.length;
          this.matchedBetslength = this.matchedBets.length;
          this.unmatchedBetslength = this.unmatchedBets.length;
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        if (this.router.url.split("?")[0] == "/main/exchange") {
          setTimeout(() => resolve(this.getAllBets()), 2000);
        }
      }
    );
  }

  RemoveBackLay(back) {
    this.betslipinfo = true;
    this.IsShowBetInfo = false;
    this.total_liability = 0;
    this.matchModel.tempArray = [];
  }

  showBetSlip(type) {
    this.betslipType = type;
  }
}
