import { Component, OnInit } from '@angular/core';
import { Match } from '../../../services/Model/match';
import { MatchService } from '../../../services/match.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Globals } from '../../../services/Model/global';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-dashmain',
    templateUrl: './dashmain.component.html',
    styleUrls: ['./dashmain.component.scss']
})
export class DashmainComponent implements OnInit {
    OneClickStackButton;
    loginUserData;
    MatchStackButton;
    btnActive;
    oneClickSelectedBet;
    one_click_enable;
    public currentRoute: string;

    constructor(public matchService: MatchService, public toastr: ToastrManager, public globals: Globals, public matchModel: Match, private router: Router) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((res: any) => {
                this.currentRoute = res.url;
            });
    }

    ngOnInit() {
        this.loginUserData = JSON.parse(localStorage.getItem('UserLoginData'));
        if (this.loginUserData != undefined) {
            let stackValues = this.loginUserData.stack.split(',');
            let oneclickStack = this.loginUserData.one_click_stack.split(',');
            this.MatchStackButton = (stackValues);
            this.OneClickStackButton = (oneclickStack);
            this.globals.btnActive = localStorage.getItem('btnActive');
            this.globals.oneClickSelectedBet = localStorage.getItem('oneClickSelectedBet');
        }

    }

    setOneClickBetStake(stake, ind) {

        this.btnActive = ind;
        this.globals.btnActive = ind;
        localStorage.setItem('btnActive', this.btnActive);
        this.oneClickSelectedBet = stake;
        localStorage.setItem('oneClickSelectedBet', this.oneClickSelectedBet);
        this.globals.oneClickSelectedBet = stake;
    }

    SaveOneClick(oneclickval) {
        let oneclickstakeData = {
            one_click: oneclickval.join(','),
        };
        this.matchService.updateUserOneClickStack(oneclickstakeData).subscribe((data) => {
            this.OneClickStackButton = oneclickval;
            this.toastr.successToastr(data.message);
        }, (err) => {
            console.log(err);
        });
    }

    isValidbuttoneclick(btns) {

        if (btns != undefined) {
            let matchStk = btns;
            for (let h = 0; h < matchStk.length; h++) {
                if (matchStk[h] == '' || matchStk[h] == null) {
                    return true;
                }
            }
        }
    }

}
