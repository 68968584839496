import { Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {SessionServiceService} from '../session-service.service';
import {environment} from '../../../environments/environment';


@Injectable()
export class Globals {
  static Url = environment.APIEndpoint;
  static Back = 'Back';
  static Lay = 'Lay';
  public loginUserData ;
  public oneClickSelectedBet;
  public IsToggle;
  public btnActive;
  one_click_enable;
  MatchStackButton;
  public  PdcData:any;
  super_admin_commission_type;

  private reqHeader: HttpHeaders;
constructor(private  sessionService: SessionServiceService) {
  this.loginUserData = JSON.parse(this.sessionService.get('UserLoginData'));
  this.reqHeader = new HttpHeaders ({
    Authorization:  localStorage.getItem('TokenId'),
  });
}

}

