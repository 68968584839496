
import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionServiceService {

  constructor() {
  }
  baseurl = environment.APIEndpoint;

  set(key, value) {
    return localStorage.setItem(key, value);
  }

  get(key) {
    return localStorage.getItem(key);
  }

  destroy(key) {
    return localStorage.removeItem(key);

  }

  setHeader() {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return headers;
  }

  setTokenHeader() {
    var AdminLoginData = JSON.parse(localStorage.getItem('AdminLoginData'));
    var token = AdminLoginData.token;
    let headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      Authorization:'Bearer '+token
    });
    return headers;
  }

}
