import {Component, OnInit} from '@angular/core';
import {UserServiceService} from '../../../services/user-service.service';
import {SessionServiceService} from '../../../services/session-service.service';
import {Router} from '@angular/router';
import {Match} from 'src/app/services/Model/match';
import {MatchService} from 'src/app/services/match.service';
import {ToastrManager} from 'ng6-toastr-notifications';
import {resolve} from 'q';
import {Globals} from '../../../services/Model/global';
import {SidebarComponent} from '../sidebar/sidebar.component';
import { ExchangeComponent } from '../../Pages/exchange/exchange.component';
import { RxjscommanService } from 'src/app/services/rxjscomman.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [SidebarComponent]
})
export class HeaderComponent implements OnInit {
    ShowMatchName;
    showGreeting;
    message;
    user_name;
    password;
    g_captcha;
    currentDate;
    sportListData;
    isShowSportsMenu;
    public isLoggedIn: any = false;
    public userName: any = '';
    public logginErrorMessage: any = '';
    private reportData: any;
    public loginUserData1: any;

    public searchText_len: any;
    public searchText: any;
    public searchResult: any;
    stackValues;
    isEditable;
    MatchStackButton;
    balanceData;
    Balance;
    Liability;
    one_click_enable;
    logo;
    staticCup;
    isActive;
    apkUrl;
    userDetails;
    terms;
    is_show_apk;

    constructor(public UserService: UserServiceService, public SessionService: SessionServiceService, private router: Router, private matchService: MatchService, public match: Match, public toastr: ToastrManager, public gloabls: Globals, public Sidebar: SidebarComponent,public _rxjscommanService:RxjscommanService) {
        this.globalConstant()

    }

    ngOnInit() {
        this.apkUrl = Globals.Url + 'uploads/app.apk';
        this.loginUserData1 =  this.gloabls.loginUserData;

        this.isActive = -1;
        this.staticCup = [ {
            'name': 'IPL',
            'match_id': '28127348'
        }];


        this.timerRun();
        this.isLoggedIn = this.SessionService.get('isLoggedIn');
        this.userName = this.SessionService.get('userName');
        this.logo = Globals.Url + 'uploads/logo.png';
        if (localStorage.length > 1) {
            this.GetSoprtLists();
            this.getChipDatabyId();
            if (localStorage.getItem('one_click_enable') == 'true') {
                this.gloabls.one_click_enable = 'true';
                this.gloabls.btnActive = localStorage.getItem('btnActive');
                this.gloabls.oneClickSelectedBet = localStorage.getItem('oneClickSelectedBet');
            } else {
                this.gloabls.one_click_enable = 'false';
            }
        }

        this.isEditable = false;
        this.userDetails = JSON.parse(localStorage.getItem('UserLoginData'));
        if (this.userDetails != null) {
            this.terms = this.userDetails.terms_conditions;
        }
    }
    globalConstant() {
        this.UserService.globalConstant().subscribe(response => {
    
          this.is_show_apk = response.data.ApkData.is_active;
         
        }, error => {
        });
      }

    timerRun() {
        this.currentDate = new Date();
        if (this.router.url.split('?')[0] != '/login') {
            setTimeout(() => resolve(this.timerRun()), 1000);
        }
    }

    loginUser() {
        //   this.g_captcha = localStorage.getItem('v3token');
        let userData = {
            user_name: this.user_name,
            password: this.password,
            g_captcha: this.g_captcha
        };
        this.UserService.loginUser(userData).subscribe((result) => {
            if (!result.error) {
                // this.userModel.setUser(this.terms);
                //  this.userModel.token = result.data.token;
                // this.auth.sendToken(result.data.token)
                this.SessionService.set('UserLoginData', JSON.stringify(result.data));
                this.SessionService.set('userName', result.data.user_name);
                this.SessionService.set('TokenId', result.data.token);
                this.SessionService.set('isLoggedIn', true);
                this.router.navigate(['exchange']);

            } else {
                if (result.type != 3) {
                    this.showGreeting = true;
                    this.logginErrorMessage = result.message;
                } else {
                    this.showGreeting = true;
                    this.logginErrorMessage = result.message;
                }
            }
        }, (err) => {
            console.log(err);
        });
    }

    logout() {
        this.matchService.logout().subscribe(data => {
            localStorage.clear();
            this.router.navigate(['login']);
            setTimeout(() => resolve(window.location.reload()), 200);

        });
        /*        this.SessionService.destroy('UserLoginData');
                this.SessionService.destroy('userName');
                this.SessionService.destroy('TokenId');
                this.SessionService.destroy('isLoggedIn');*/


    }

    fetchUsers() {
        this.searchText_len = this.searchText.trim().length;
        if (this.searchText_len > 0) {
            this.reportData = this.searchText;
            this.matchService.searchMatchList(this.reportData).subscribe((data) => {
                this.searchResult = data.data;
            });
        }
    }

    // Set value to search box
    setValue(index, $event) {

        this.searchText = this.searchResult[index].matchName;
        this.CallSelectedItem(this.searchResult[index]);
        this.searchResult = {};
        $event.stopPropagation();
    }

    searchboxClicked($event) {
        $event.stopPropagation();
    }
    isshowSearchBox= false;
    serachInput(){
        this.isshowSearchBox= !this.isshowSearchBox
    }
    document() {
        this.searchResult = {};
        this.searchText = '';
    }

    CallSelectedItem(series) {
        if (series != '') {
            // this.document();
            localStorage.setItem('selectedMatch', JSON.stringify(series));
            //   this.router.navigate(['dashboard/home']);
            this.router.navigate(['main/exchange-details'], {queryParams: {MatchId: series.match_id, SportId: series.sport_id}});
            this.searchResult = [];
            this.searchText = '';
            this.searchText_len = 0;
        }
    }

    GetSoprtLists() {
        this.sportListData = [];
        let param = {
            status: 1
        };
        this.matchService.GetSoprtName(param).subscribe((data) => {
            this.sportListData = data.data.list;
            // set default sport match list
            /*      this.defaultSportID = data.data.list[0].sport_id;
                  localStorage.setItem('defaultSportID',this.defaultSportID);
                  this.defaultSportID = localStorage.getItem('defaultSportID');*/
            /*    if(this.isClicked == false){
                  this.getMatchList();
                  this.currentSportID = this.defaultSportID;
                }*/
        }, (err) => {
            console.log(err);
        });
    }

    goToReports(path) {
        this.router.navigate(['s/' + path]);
        document.getElementById('accountPopup').classList.toggle('show');

    }

    ChangeSport(ind, sport) {
        // alert()
        //debugger
        this.match.isShowSportsMenu = false;
        this.isActive = ind;
        console.log(this.isActive);
        localStorage.setItem('sportData', JSON.stringify(sport));
        this.router.navigate(['dashmain/exchange'], {queryParams: {SportId: sport.sport_id}});

    }
    gotoCup(sport) {
        //debugger
        this.isActive = -21;
        localStorage.setItem('cupData', JSON.stringify(sport));
        
        this.router.navigate(['dashmain/exchange'], {queryParams: {SportId: sport,refreshMenu: true}});

    }
   gotoCasino(id) {
        this.isActive = +id;
        
        // localStorage.setItem('selectedMatch', JSON.stringify({ "match_id": id, "user_id": 4314 }));
        

        this.router.navigate(['game',id]);
        this._rxjscommanService.setChangeSeriesGame(id)
    }

    // gotoUserMatchOddsPage(Match) {
    //     this.isActive = -3;
    //     localStorage.setItem('selectedMatch', JSON.stringify(Match));
    //     this.ShowMatchName = Match.name;
    //     this.router.navigate(['main/exchange-details'], {queryParams: {SportId: 4, refreshMenu: true}});
    // }

    gotoEchanges() {
        // alert()
        //debugger;
        this.match.isShowSportsMenu = true;
        this.Sidebar.GetSoprtName();
        this.isActive = -11;
        this.router.navigate(['dashmain/exchange'], {queryParams: {SportId: 4, refreshMenu: true}});

    }

    // gotoMultiMarket() {
    //     this.match.isShowSportsMenu = true;
    //     this.isActive = -2;
    //     this.router.navigate(['main/multiMarkets'], {queryParams: {SportId: 4, refreshMenu: true}});

    // }

    showSettingPopup() {
        document.getElementById('StackSetting').classList.toggle('show');
    }

    showAcountPopup() {
        document.getElementById('accountPopup').classList.toggle('show');
    }

    getUserMatchStack() {
        this.matchService.getUserMatchStack().subscribe((data) => {
            this.stackValues = data.data;
            let stackValues = this.stackValues.split(',');
            this.stackValues = (stackValues);
            this.gloabls.MatchStackButton = this.stackValues;

        }, (err) => {
            console.log(err);
        });
    }

    updateUserMatchStack(stackVal) {

        var params = {
            'match_stack': stackVal.join(',')
        };
        this.matchService.updateUserMatchStack(params).subscribe((data) => {
            //debugger;

            let stackValues = stackVal;
            this.MatchStackButton = (stackValues);
            this.stackValues = (stackValues);
            this.gloabls.MatchStackButton = this.stackValues;
            localStorage.setItem('MatchStackButton', this.stackValues.join(','));
            this.toastr.successToastr(data.message);
        }, (err) => {
            console.log(err);
        });
    }

    isValidbutt(btns) {
        if (btns != undefined) {
            let matchStk = btns;
            for (let h = 0; h < matchStk.length; h++) {
                if (matchStk[h] == '') {
                    return true;
                }
            }
        }
    }

    cancelPop() {
        document.getElementById('StackSetting').classList.toggle('show');
    }

    getChipDatabyId() {
        this.matchService.getChipDatabyId().subscribe((data) => {
                if (!data.error) {
                    if (data.data != undefined) {

                        this.match.marqueText = data.data.site_message;
                        this.balanceData = data.data;
                            this.Balance = data.data.balance;
                        //  this.FreeChips = data.data.freechips;
                           this.Liability = data.data.liability;
                        //   this.P_L = data.data.profit_loss;
                        if (data.data.lock_user == '1' || data.data.close_account == '1' || data.data.is_online == '0') {
                            this.logout();
                        }
                    }

                } else if (data.code == 412) {
                    localStorage.clear();
                    this.router.navigate(['login']);
                    setTimeout(() => resolve(window.location.reload()), 200);

                }

            }, (err) => {
                //debugger;
                if (err.code == 412) {
                    this.router.navigate(['login']);

                }
                console.log(err);
                if (this.router.url.split('?')[0] != '/login') {
                    setTimeout(() => resolve(this.getChipDatabyId()), 4000);
                }
            },
            () => {
                if (this.router.url.split('?')[0] != '/login') {
                    setTimeout(() => resolve(this.getChipDatabyId()), 4000);
                }
            });
    }

    getStakesett() {
        this.gloabls.IsToggle = true;
    }

    setOnclickEnable() {
        //debugger;
        if ((localStorage.one_click_enable) == 'true') {
            this.gloabls.one_click_enable = 'false';
            this.gloabls.IsToggle = false;
            localStorage.setItem('one_click_enable', this.gloabls.one_click_enable);
        } else {
            this.gloabls.IsToggle = true;
            this.gloabls.one_click_enable = 'true';
            let loginUserData = JSON.parse(localStorage.getItem('UserLoginData'));
            let oneclickStack = loginUserData.one_click_stack.split(',');
            localStorage.setItem('one_click_enable', this.gloabls.one_click_enable);
            this.gloabls.btnActive = 0;
            localStorage.setItem('btnActive', this.gloabls.btnActive);
            this.gloabls.oneClickSelectedBet = oneclickStack[0];
            localStorage.setItem('oneClickSelectedBet', this.gloabls.oneClickSelectedBet);

        }


    }

    trackByFn(index: any, item: any) {
        return index;
    }
}
