import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserServiceService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.APIEndpoint;
  reqHeader1 = new HttpHeaders ({
    'Content-Type': 'application/json'
  });

  loginUser(userData): Observable<any> {
    console.log("user dat>>>>>>>>>>>>>>>>>>",userData)
    return this.http.post<any>(  this.baseUrl + 'userLogin', userData, {headers: this.reqHeader1});
  }

  logout (): Observable<any> {
    return this.http.post<any>( this.baseUrl+'logout','', {headers: this.reqHeader1});
  }
  globalConstant(): Observable<any> {
    return this.http.get<any>( this.baseUrl+'globalConstant',{ headers : this.reqHeader1});
  }
}
