import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common'
import {MainComponent} from './Views/layout/main/main.component';
import {SubmainComponent} from './Views/layout/submain/submain.component';
import {AppComponent} from './app.component';
import {HeaderComponent} from './Views/Partial/header/header.component';
import {SidebarComponent} from './Views/Partial/sidebar/sidebar.component';
import {AppRoutingModule} from './routes/app-routing.module';
import {Match} from './services/Model/match';
import {Globals} from './services/Model/global';
import {RightbarComponent} from './Views/Partial/rightbar/rightbar.component';
import {DashmainComponent} from './Views/layout/dashmain/dashmain.component';
import { FooterComponent } from './Views/Partial/footer/footer.component';
import { ExchangeComponent } from './Views/Pages/exchange/exchange.component';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
 
import { CasionSeriesMatchComponent } from './casion-series-match/casion-series-match.component';




@NgModule({
    declarations: [
        AppComponent, MainComponent, HeaderComponent, SidebarComponent, SubmainComponent, RightbarComponent , DashmainComponent, FooterComponent, CasionSeriesMatchComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BsDatepickerModule.forRoot(),
        DatepickerModule.forRoot(),
        AppRoutingModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule
    ],
    providers: [Globals, Match,{provide : LocationStrategy , useClass: HashLocationStrategy}],
    bootstrap: [AppComponent]
})
export class AppModule { }
