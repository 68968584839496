import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatchService } from 'src/app/services/match.service';
import { Globals } from 'src/app/services/Model/global';
declare var $:any;


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public userDetails: any;
  public terms: any;
  message;
  old_password;
  newpassword;
  Renewpassword;
  loginUserData1;
  footerLogo;
  chanPassForm:FormGroup;

  constructor(public matchService: MatchService ,public globals: Globals, public toastr: ToastrManager, private formBuilder: FormBuilder) {
    this.userDetails = JSON.parse(localStorage.getItem('UserLoginData'))
    this.chanPassForm = formBuilder.group({
      old_password: ['', Validators.required],
      newpassword: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d@$!%*#?&]{6,}$')
      ]],
      Renewpassword: ['', Validators.required]
    });
   }

  ngOnInit() {

    if(this.userDetails.is_change_password == '1')
      $('#changePass').show();
  }

  changePassword () {
    this.loginUserData1 = {
      "oldPassword": this.old_password,
      "newPassword": this.newpassword,
      "confirmNewPassword":this.Renewpassword
    }
    this.matchService.changePassword(this.loginUserData1).subscribe((result) => {
      this.message = result.message;
      if(!result.error){
        this.userDetails.is_change_password='0';
        localStorage.setItem('UserLoginData',JSON.stringify(this.userDetails));
        $('#changePass').hide();
      }else {
        this.toastr.errorToastr(result.message);

      }
    }, (err) => {

    });
  }
}
