import { Component, OnInit } from '@angular/core';
import { SportServiceService } from '../../../services/sport-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatchService } from '../../../services/match.service';
import { Match } from '../../../services/Model/match';
import { RxjscommanService } from 'src/app/services/rxjscomman.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public countryListCode : any

  public FsportData: any;
  GetSeriesData: any;
  public selectedSport: any;
  GetMatchData: any;
  private accordionLv2: string;
  private showDisplay: boolean;
  GetSeriesDatalength: any;
  GetMatchDatalength: any;
  ShowMatchName: any;
  SportId;
  IsshowSeries;
  IsshowSports;
  ShowSportName;
  SelectedSport;
  IsshowMatches;
  ShowSriesName;
  refreshMenu;
  Seriesid
  constructor(public SportService: SportServiceService, private router: Router, private route: ActivatedRoute, public match: Match, public _rxjscommanService:RxjscommanService) {
    //debugger
    this.route.queryParams.subscribe(params => {
      this.SportId = params['SportId'];
      this.refreshMenu = params['refreshMenu'];
      this.Seriesid = params['Seriesid'];

      if (this.SportId !== 'cup') {
        if (this.refreshMenu && this.SportId == 4) {
          this.GetSoprtName();
        } else if (this.Seriesid == undefined && this.SportId != undefined) {
          this.getSeriesLstData(this.SportId);
        }
      }

      if(this.SportId == '7' || this.SportId == '4339'){
        this.getMatchCountryList(this.SportId)
      }

    });

  }

  ngOnInit() {
    //debugger
    if (this.SportId !== 'cup') {
      if (localStorage.length > 1)
        this.GetSoprtName();
      this.IsshowSports = true;
    }
  }

  getSeriesLstData(sport_id) {
    this.match.isShowSportsMenu = false;

    var SportData = {
      'sport_id': '' + sport_id,
      limit: null,
      pageno: null,
      series_name: null,
      status: 1,

    };
    this.SportService.getSeriesLst(SportData).subscribe((data) => {
      this.GetSeriesData = data.data.list;
      this.IsshowSports = false;
      this.IsshowSeries = true;
      this.IsshowMatches = false;
      if (this.GetSeriesData != undefined)
        this.ShowSportName = JSON.parse(localStorage.getItem('sportData'));
      this.ShowSriesName = [];
      this.ShowMatchName = [];
      this.GetSeriesDatalength = this.GetSeriesData.length;
      this.SelectedSport = JSON.parse(localStorage.getItem('sportData'));
      console.log(this.selectedSport , this.ShowSportName, "sport data")
    }, (err) => {
      console.log(err);
    });
    //}
  }


  GetSoprtName() {

    this.FsportData = [];
    let param = {
      status: 1
    };
    this.SportService.GetSoprtName(param).subscribe((data) => {
      if (!data.error) {
        this.FsportData = data.data.list;
        localStorage.setItem('Sportname', 'Cricket');
        localStorage.setItem('CricketData', data.data.list[0]);
        localStorage.setItem('sportData', data.data.list[0]);
        this.IsshowSports = true;
        this.IsshowSeries = false;
        this.IsshowMatches = false;
        this.ShowSportName = [];
        this.ShowSriesName = [];
        this.ShowMatchName = [];

      }
    }, (err) => {
      console.log(err);
    });
  }
  

  gotoUserMatchOddsPage(Match) {

    localStorage.setItem('selectedMatch', JSON.stringify(Match));
    this.ShowMatchName = Match.name;
    this._rxjscommanService.setChangeGame(true)

    this.router.navigate(['main/exchange-details']);
  }

  getSeriesLst(displaydata) {
    this.match.isShowSportsMenu = false;

    var SportData = {
      'sport_id': '' + displaydata.sport_id,
      limit: null,
      pageno: null,
      series_name: null,
      status: 1,

    };
    this.SportService.getSeriesLst(SportData).subscribe((data) => {
      this.GetSeriesData = data.data.list;
      this.IsshowSports = false;
      this.ShowSportName = displaydata;
      this.IsshowSeries = true;
      this.IsshowMatches = false;
      this.ShowSriesName = [];
      this.ShowMatchName = [];
      this.GetSeriesDatalength = this.GetSeriesData.length;
      localStorage.setItem('Sportname', displaydata.name);
      localStorage.setItem('sportData', JSON.stringify(displaydata));
      this.SelectedSport = JSON.parse(localStorage.getItem('sportData'));
    }, (err) => {
      console.log(err);
    });
    //}
  }


  getMatchLst(sportid, seriesid) {
    var matchdata = {
      'sport_id': '' + sportid,
      'series_id': seriesid,
    };
    this.SportService.getUserFavouriteMatchLst(matchdata).subscribe((data) => {
      this.GetMatchData = data.data;
      this.IsshowSports = false;
      this.IsshowSeries = false;
      this.IsshowMatches = true;
      this.ShowMatchName = '';
      this.GetMatchDatalength = this.GetMatchData.length;
      //   this.router.navigate(['dashmain/exchange'], {queryParams: {Seriesid: seriesid, SportId: sportid}});

    }, (err) => {
      console.log(err);
    });
  }



  previous() {
    this.showDisplay = !this.showDisplay;
    //this.router.navigate(['dashboard/home'], {queryParams: {Seriesid: 0, SportId: 4}});

    this.GetSoprtName();
  }

  showMatch(value) {
   

    this.showDisplay = !this.showDisplay;
    this.ShowSriesName = value;


  }

  addMenuClass() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('menumobile');
  }


  getMatchCountryList(spirtID){
    this.countryListCode = []
    let payload = {
      sport_id : spirtID
    };
    this.SportService.getCountrylistBymatchId(payload).subscribe((data) => {
      // this.GetMatchData = data.data;
  
      if(data.message == 'success'){
        this.countryListCode = data.data
        // console.log(this.countryListCode , "data")
      } else {
        // console.log("dat55a", data)
      }
    }, (err) => {
      console.log(err);
    });
  }


  countryCode(countryCode){
      
  }
}
