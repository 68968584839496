import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionServiceService} from './session-service.service';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SportServiceService {
    public UserLoginData = JSON.parse(this.sessionService.get('UserLoginData'));
    public token = this.sessionService.get('TokenId');
    // reqHeader = new HttpHeaders ({
    //   Authorization: 'Bearer '+ this.token,
    //   'Content-Type': 'application/json'
    // });
    Base_Url = environment.APIEndpoint;

    constructor(private http: HttpClient, public sessionService: SessionServiceService) {

        //  this.loginUserData = this.globals.loginUserData;
        /*    this.user = this.loginUserData.user;
            this.lgPassword = this.loginUserData.lgPassword;
            localStorage.setItem('user', this.user);
            localStorage.setItem('lgPassword', this.lgPassword);*/

    }

    setHeader() {
        let reqHeader = new HttpHeaders().set('Content-Type', 'application/json');
        reqHeader = reqHeader.set('Authorization', 'Bearer ' + localStorage.getItem('TokenId'));
        return reqHeader;
    }
    getMatchname(): Observable<any> {
        return this.http.get<any>(  'https://score.crakex.in:3290/tv_code');
    }
    getTvUrl(): Observable<any> {
        return this.http.get<any>(this.Base_Url +'gettvurl',{headers: this.setHeader()});
    }
    GetSoprtName(param): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'getAllSports', param, {headers: this.setHeader()});
    }
    getRunTimeFancyPosition(params): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'getRunTimeFancyPosition', params, {headers: this.setHeader()});
    }
    getSeriesLst(SportID: any): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'getAllSeries', SportID, {headers: this.setHeader()});
    }

    getMatchLst(SportID: any): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'getAllMatches', SportID, {headers: this.setHeader()});
    }

    getUserFavouriteMatchLst(SportData): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'homematches', SportData, {headers: this.setHeader()});
    }


    getCountrylistBymatchId(data : any): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'matches/get-match-country-list-by-sport-id', data, {headers: this.setHeader()});
    }

    getMatchVenuListbyCountryandSportId(data : any): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'matches/get-match-venue-list-by-country-code-sport-id', data, {headers: this.setHeader()});
    }


    deleteUserbet(betId, UsereID): Observable<any> {
        return this.http.get<any>(this.Base_Url + '/Betentrycntr/deleteGetbetting/' + betId + '/' + UsereID, {headers: this.setHeader()});
    }

    setFavorite(matchdata): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'addMarketFavourite', matchdata, {headers: this.setHeader()});
    }

    setUnFavorite(matchdata): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'addMarketFavourite', matchdata, {headers: this.setHeader()});
    }

    getChipDatabyId(): Observable<any> {
        return this.http.get<any>(this.Base_Url + 'getUserBalance', {headers: this.setHeader()});
    }

    matchDetails(params): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'matchDetails', params, {headers: this.setHeader()});
    }

    getAllFavouriteMarket(): Observable<any> {
        return this.http.get<any>(this.Base_Url + 'getAllFavouriteMarket', {headers: this.setHeader()});
    }

    getUserMatchStack(): Observable<any> {
        return this.http.get<any>(this.Base_Url + 'getUserMatchStack', {headers: this.setHeader()});
    }

    updateUserMatchStack(param): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'updateUserMatchStack', param, {headers: this.setHeader()});
    }

    updateUserOneClickStack(param): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'updateUserOneClickStack', param, {headers: this.setHeader()});
    }

    saveBet(params): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'bet/saveBet', params, {headers: this.setHeader()});
    }
    syncupRecord(params): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'bet/sync-up-records', params, {headers: this.setHeader()});
    }
    saveFancyBet(params): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'bet/saveFancyBet', params, {headers: this.setHeader()});
    }

    getBetsByMatchId(params): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'bet/getBetsByMatchId', params, {headers: this.setHeader()});
    }

    getAllBets(): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'bet/getAllBets', '', {headers: this.setHeader()});
    }

    GetScoreApi(matchId): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'scoreBoard', matchId, {headers: this.setHeader()});
    }

    searchMatchList(reportData: any): Observable<any> {
        return this.http.get<any>(this.Base_Url + 'searchMatches?search=' + reportData, {headers: this.setHeader()});
    }

    logout(): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'logout', '', {headers: this.setHeader()});
    }

    getAllActiveNotifications(param): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'notifications/getAllActiveNotifications', param, {headers: this.setHeader()});
    }

    changePassword(loginUserData): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'updatePassword', loginUserData, {headers: this.setHeader()});
    }
    teenPattiMarketResult (loginUserData): Observable<any> {
        return this.http.post<any>( this.Base_Url + 'result/teenPattiMarketResult', loginUserData,{headers: this.setHeader()});
    }
    Market32Result (loginUserData): Observable<any> {
        return this.http.post<any>( this.Base_Url + 'result/teenPattiMarketResult', loginUserData,{headers: this.setHeader()});
    }
    marketResultListByMatchId(loginUserData): Observable<any> {
        return this.http.post<any>( this.Base_Url+'result/marketResultListByMatchId', loginUserData,{headers: this.setHeader()});
    }
    marketResultListByMatchIdWithOutPagination(loginUserData): Observable<any> {
        return this.http.post<any>( this.Base_Url+'result/marketResultListByMatchIdWithOutPagination', loginUserData,{headers: this.setHeader()});
    }
    getLiveSportMatchList(loginUserData): Observable<any> {
        return this.http.post<any>( this.Base_Url+'matches/getLiveSportMatchList', loginUserData,{headers: this.setHeader()});
    }
    getMarketResultView(param): Observable<any> {
        return this.http.post<any>(this.Base_Url + 'get-marketResultView', param, {headers: this.setHeader()});
    }
}
