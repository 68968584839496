import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RxjscommanService {

  public gotonewGame = new Subject<any>();
  public gotonewSeriesGame = new Subject<any>();


  setChangeGame(data: any) {
    this.gotonewGame.next({ stackData: data });
  }
  getChangeGame(): Observable<any> {
    return this.gotonewGame.asObservable();
  }
  setChangeSeriesGame(data: any) {
    this.gotonewSeriesGame.next({ seriesData: data });
  }
  getChangeSeriesGame(): Observable<any> {
    return this.gotonewSeriesGame.asObservable();
  }
  constructor() { }
}