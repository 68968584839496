import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RxjscommanService } from '../services/rxjscomman.service';

@Component({
  selector: 'app-casion-series-match',
  templateUrl: './casion-series-match.component.html',
  styleUrls: ['./casion-series-match.component.scss']
})
export class CasionSeriesMatchComponent implements OnInit {
  public sportId;
subscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute,public _rxjscommanService:RxjscommanService) { 
    this.sportId = this.route.snapshot.paramMap.get('sportId')
        console.log(this.sportId)
    this.subscription = this._rxjscommanService.getChangeSeriesGame().subscribe(res => {
      if (res) {
        this.sportId= res.seriesData
           
      } else {
        // clear messages when empty message received
      }
    });
    
    
  }

  ngOnInit() {
  }
  GotoMatchDetails(matchId) {
    
    localStorage.setItem('selectedMatch', JSON.stringify({ "match_id": matchId, "user_id": 4314 }));
    this.router.navigate(['main/exchange-details']);
  }

}
